import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */
/* @jsx mdx */
import { EuiSpacer } from '@elastic/eui';
import BreadCrumbs from 'components/Layout/BreadCrumbs';
import SEOHelmet from 'components/Layout/SEOHelmet';
import TriggerTable from 'components/Frequently_Asked_Question/WQHD_MQTTv5_Alarmserver_APIv2_NodeRED/triggerTable';
import TriggerCombinedTable from 'components/Frequently_Asked_Question/WQHD_MQTTv5_Alarmserver_APIv2_NodeRED/triggerCombinedTable';
export const _frontmatter = {
  "title": "MQTT- and HTTP-Alarmserver v2 API",
  "path": "/Frequently_Asked_Question/WQHD_MQTTv5_Alarmserver_Testing/",
  "dateChanged": "2024-09-30",
  "author": "Mike Polinowski",
  "excerpt": "Testing the MQTT and HTTP Alarmserver v2 API.",
  "image": "../FAQ_SearchThumb.png",
  "social": "/images/Search/FAQ_SearchThumb.webp",
  "toc": "/images/Search/TOC_Icons/Wiki_Tiles_FAQs_white.webp",
  "chapter": "FAQs",
  "faq": "WQHD & UHD Cameras"
};
const layoutProps = {
  _frontmatter
};
const MDXLayout = "wrapper";
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">



    <SEOHelmet title='MQTT- and HTTP-Alarmserver v2 API' dateChanged='2024-09-30' author='Mike Polinowski' tag='INSTAR IP Camera' description='Testing the MQTT and HTTP Alarmserver v2 API.' image='/images/Search/FAQ_SearchThumb.png' twitter='/images/Search/FAQ_SearchThumb.webp' location={props.location.pathname} mdxType="SEOHelmet" />
    <BreadCrumbs locationBC={props.location} locationEN={props.location.pathname} locationDE='/de/Frequently_Asked_Question/WQHD_MQTTv5_Alarmserver_Testing/' locationFR='/fr/Frequently_Asked_Question/WQHD_MQTTv5_Alarmserver_Testing/' crumbLabel="FAQs" mdxType="BreadCrumbs" />
    <h1 {...{
      "id": "testing-the-mqtt-and-http-alarmserver-v2-api",
      "style": {
        "position": "relative"
      }
    }}><a parentName="h1" {...{
        "href": "#testing-the-mqtt-and-http-alarmserver-v2-api",
        "aria-label": "testing the mqtt and http alarmserver v2 api permalink",
        "className": "header-link-icon before"
      }}><svg parentName="a" {...{
          "aria-hidden": "true",
          "focusable": "false",
          "height": "16",
          "version": "1.1",
          "viewBox": "0 0 16 16",
          "width": "16"
        }}><path parentName="svg" {...{
            "fillRule": "evenodd",
            "d": "M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z"
          }}></path></svg></a>{`Testing the MQTT and HTTP Alarmserver v2 API`}</h1>
    <p><strong parentName="p">{`Q`}</strong>{`: I read `}<a parentName="p" {...{
        "href": "/en/Frequently_Asked_Question/Alarmserver_Testing/"
      }}>{`this article`}</a>{` showing how to test my IN-9408 2k+ WQHD cameras `}<a parentName="p" {...{
        "href": "/en/Web_User_Interface/1440p_Series/Smarthome/Alarm_Server/"
      }}>{`alarmserver`}</a>{`. There is an older article explaining the use of the `}<a parentName="p" {...{
        "href": "/en/Frequently_Asked_Question/WQHD_MQTTv5_Alarmserver_Testing_Deprecated_v1/"
      }}>{`deprecated v1 API`}</a>{`. What do I have to change here for the version 2 API?`}</p>
    <p><strong parentName="p">{`A`}</strong>{`: Both - the `}<a parentName="p" {...{
        "href": "/en/Web_User_Interface/1440p_Series/Smarthome/Alarm_Server/"
      }}>{`HTTP`}</a>{` and `}<a parentName="p" {...{
        "href": "/en/Web_User_Interface/1440p_Series/Smarthome/MQTT/"
      }}>{`MQTT`}</a>{` Alarmserver - now use an encoded output that allows the differentiation of multiple simultaneous alarm triggers (before you always only received the first trigger if more then one was tripped). Both can still be tested using the `}<a parentName="p" {...{
        "href": "/en/Frequently_Asked_Question/WQHD_MQTTv5_Alarmserver_Testing_Deprecated_v1/"
      }}>{`MQTT`}</a>{` or `}<a parentName="p" {...{
        "href": "/en/Frequently_Asked_Question/Alarmserver_Testing/"
      }}>{`HTTP API`}</a>{`. But now it is also possible to use the cameras webUI to check the alarmserver output for different triggers:`}</p>
    <h2 {...{
      "id": "alarm-trigger",
      "style": {
        "position": "relative"
      }
    }}><a parentName="h2" {...{
        "href": "#alarm-trigger",
        "aria-label": "alarm trigger permalink",
        "className": "header-link-icon before"
      }}><svg parentName="a" {...{
          "aria-hidden": "true",
          "focusable": "false",
          "height": "16",
          "version": "1.1",
          "viewBox": "0 0 16 16",
          "width": "16"
        }}><path parentName="svg" {...{
            "fillRule": "evenodd",
            "d": "M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z"
          }}></path></svg></a>{`Alarm Trigger`}</h2>
    <p>{`Depending on whether you want to use the `}<a parentName="p" {...{
        "href": "/en/Web_User_Interface/1440p_Series/Smarthome/MQTT/"
      }}>{`MQTT`}</a>{` or `}<a parentName="p" {...{
        "href": "/en/Web_User_Interface/1440p_Series/Smarthome/Alarm_Server/"
      }}>{`HTTP`}</a>{` alarmserver open the corresponding menu in your cameras webUI and click on the `}<strong parentName="p">{`Test`}</strong>{` button in the bottom left to open the Test Menu.`}</p>
    <p>{`In the following example I will use the HTTP alarmserver. Make sure that configured the alarmserver and you activated the appending of alarm triggers:`}</p>
    <p><span parentName="p" {...{
        "className": "gatsby-resp-image-wrapper",
        "style": {
          "position": "relative",
          "display": "block",
          "marginLeft": "auto",
          "marginRight": "auto",
          "maxWidth": "908px"
        }
      }}>{`
      `}<a parentName="span" {...{
          "className": "gatsby-resp-image-link",
          "href": "/en/static/98692476710d550ad346cb4ded448c9c/a2b88/AS_Server_Test_WebUI_01.png",
          "style": {
            "display": "block"
          },
          "target": "_blank",
          "rel": "noopener"
        }}>{`
    `}<span parentName="a" {...{
            "className": "gatsby-resp-image-background-image",
            "style": {
              "paddingBottom": "95.21739130434781%",
              "position": "relative",
              "bottom": "0",
              "left": "0",
              "backgroundImage": "url('data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABQAAAATCAYAAACQjC21AAAACXBIWXMAAAsTAAALEwEAmpwYAAADFUlEQVQ4y42Uy27TQBSG8xIIiRanSWM7adK7CmrSxE4vSYXq24xnfCttk/QGSBVqWcACITawYAECdqwQOyQkxIOwofAGfY4fzaQuCa0Qi1/HY8mfz38uk6lUKpicmoaaG0V79wV2P54hePsDwbtfIG9+grw5HYj9Z+/1KeL3p7j36QzW8QfcuH4NIyOjUBQFGQGsVCahawUYLRt8/zFo9xi0ewLafQTae/QnnsvrnIB0jhHsP8bKBkdWUZAfL0DVdGQmJiYgpOs6GvUlsKQDN+yAbe4h2NoH39pDtHMoJc7h9gHY3T14UQd+0sPK8gpm5hYwPTeP9Q0LmXK5DCEJbBjwGUMUx+j1drGz05FnSn34PoPreiCEgnEOzyPwGUezuYxxVUdlZh63a42/gQ0kSYI4jhFFEXzfByFEigrRfiTEA/Ec8O5DNFfXkVNuQlV1qFrpD1DTNNTrdQkLggCMMXieJyXAPNoEi7fB4y3weBssTBB0j9BcbSGnjKCQzyGfy/drOAgMwlDCBETARHaO48ChIWyWwKIRLBrDIiFcP0Kr1UJRy2N6qozJyfJloLAqgELkHCg+MowG6vUl1KpVqWp1EaZhYG1tFbMzFdy6NYeFhbnLltPaua57kaEAivrWajVUJayGxcVF+c40GygVC5idnYKua32gmMW0KWEYSmCq1LL4gYi2bcOyLBlFvQ3DkAOdy+WQzWaRKRaLQ8C0KeF5LdMu/y3m+zg6OhoCCv0TyDmXEoC046kIpTg8OLwaODiHUdQHcjm8A4A0O4/AJgQ9y0XnAUO9bUIZ/Y8MhVLIEFhEYdlxEOzeh7G6BuXm6NUZpl0etHlV/VLLYj0vWU67LMamn2G6ct7wpnB+MZ9Svi/X1DTNYWCpVJK3jaqqMsMkiRGFAfh5hwVQwEQpGOPyYuASyGTjhoBjY33LApoCPZ9jw6FwiH8BpJSCigvBtUFdGy4lsIR9zi9b7mdYQkFVYTbq+PbsDr6/auHzkzZsl8jrynM24B08Q/D8K9ynn/HSCvFlZR2f2hbaZhPZ8QLGC5rUbw7AuibsTmLUAAAAAElFTkSuQmCC')",
              "backgroundSize": "cover",
              "display": "block"
            }
          }}></span>{`
  `}<picture parentName="a">{`
          `}<source parentName="picture" {...{
              "srcSet": ["/en/static/98692476710d550ad346cb4ded448c9c/e4706/AS_Server_Test_WebUI_01.avif 230w", "/en/static/98692476710d550ad346cb4ded448c9c/d1af7/AS_Server_Test_WebUI_01.avif 460w", "/en/static/98692476710d550ad346cb4ded448c9c/984a9/AS_Server_Test_WebUI_01.avif 908w"],
              "sizes": "(max-width: 908px) 100vw, 908px",
              "type": "image/avif"
            }}></source><source parentName="picture" {...{
              "srcSet": ["/en/static/98692476710d550ad346cb4ded448c9c/a0b58/AS_Server_Test_WebUI_01.webp 230w", "/en/static/98692476710d550ad346cb4ded448c9c/bc10c/AS_Server_Test_WebUI_01.webp 460w", "/en/static/98692476710d550ad346cb4ded448c9c/1f8a9/AS_Server_Test_WebUI_01.webp 908w"],
              "sizes": "(max-width: 908px) 100vw, 908px",
              "type": "image/webp"
            }}></source>{`
          `}<source parentName="picture" {...{
              "srcSet": ["/en/static/98692476710d550ad346cb4ded448c9c/81c8e/AS_Server_Test_WebUI_01.png 230w", "/en/static/98692476710d550ad346cb4ded448c9c/08a84/AS_Server_Test_WebUI_01.png 460w", "/en/static/98692476710d550ad346cb4ded448c9c/a2b88/AS_Server_Test_WebUI_01.png 908w"],
              "sizes": "(max-width: 908px) 100vw, 908px",
              "type": "image/png"
            }}></source>{`
          `}<img parentName="picture" {...{
              "className": "gatsby-resp-image-image",
              "src": "/en/static/98692476710d550ad346cb4ded448c9c/a2b88/AS_Server_Test_WebUI_01.png",
              "alt": "MQTT- und HTTP-Alarmserver v2 API",
              "title": "MQTT- und HTTP-Alarmserver v2 API",
              "loading": "lazy",
              "decoding": "async",
              "style": {
                "width": "100%",
                "height": "100%",
                "margin": "0",
                "verticalAlign": "middle",
                "position": "absolute",
                "top": "0",
                "left": "0"
              }
            }}></img>{`
        `}</picture>{`
  `}</a>{`
    `}</span></p>
    <p>{`And click on the `}<strong parentName="p">{`Test`}</strong>{` button to open the Test Menu:`}</p>
    <p><span parentName="p" {...{
        "className": "gatsby-resp-image-wrapper",
        "style": {
          "position": "relative",
          "display": "block",
          "marginLeft": "auto",
          "marginRight": "auto",
          "maxWidth": "918px"
        }
      }}>{`
      `}<a parentName="span" {...{
          "className": "gatsby-resp-image-link",
          "href": "/en/static/a1da5308df3cf234a2fc8737502e7a9a/e0885/AS_Server_Test_WebUI_02.png",
          "style": {
            "display": "block"
          },
          "target": "_blank",
          "rel": "noopener"
        }}>{`
    `}<span parentName="a" {...{
            "className": "gatsby-resp-image-background-image",
            "style": {
              "paddingBottom": "94.34782608695652%",
              "position": "relative",
              "bottom": "0",
              "left": "0",
              "backgroundImage": "url('data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABQAAAATCAYAAACQjC21AAAACXBIWXMAAAsTAAALEwEAmpwYAAADNElEQVQ4y4WUy27jVByH8wZILFjRie8+59hpp03apCOkqnSaOGnS1JckzcVJGTFFLEAI2CJ4h4ERYoHEhh0rnobyAIin+NCx4zJFHbH46RzZ53z+/W+uhWFIGDbwbIPx1z/z4pe/mP/wB9ev/yR7dUf2/SN6dUf+4x2f/vo3Z5tvee/dd9jZeYLjetQ0TEOl8GmeDGlffMRhlHMYrWn11rSiN9Qr1ezlHEU5z0Yv2Ouc8mTnfVzXQ6qQmlQKtZXvObRaLY6O2hy123SOjwsd/0ftdpt2u8PBwQHC99lvHrHfPOS8f0EtCAK0pJSEjQZxkjJfLJnPFyz0ulgUe63JdMpkUmo2u2Y4GuF6AqFC9pptPjj5sARqd1IqgkBxmUyY5LesNzfc3DzUZrO513q9pj8Y4DgOtmnhuD5B4+nWYQGUBXg2m/Hy9pYkSUmS5K3KsozBYIDr2DhmHcuoI4SiprYhCyEKoD48v74mjuO3wvS7CqikQPoOuw1Fo7FLTakq5NJhkqbkeU6a/r/Dfr9PGCoODhp0Oi2arWZVlDeASVIA9QW91+ASnj4asuc5NELB/n4Dbe5BlfWqD+uk6wtXV1ePhq6f6Y9ooGVZmKaJYdSLXqwJIR8A9eF8tWS1XLBaLovW0YWaTqelJhPSOGZSAW0bwzAw6nU8zy+BxaQUQMU4TskWG9LrnMVqTb5a3btN4phREtOPY4ZZSjQYYFsWhmEWUMe5d6gIlMRTDT7Lh/z+3Yjfvhny+WbM5VVKlqVkaco4y/hqNOan/iWvL8bM+wMM28Y0DExTy6Im9IQUzS0RMiC5OOPLvMsXyy7ZZY9uLyKKSnWjiOl5l0+ed3l53iM6PcXcArVDy3a0Q3E/KXqvx8jyQwxX4vmySIWW2K6elJhS4IYhu0/3sLc5LF3aD6usgeG2jYqGleK+4SsFW+lzOvcVsIBadvm3qWAFMAwK6RRUz4Pt5WqqqnHV0rNcAuvl/1AXpYL5QuAJhSsUvvz3Q4W7YsRcpPDwpcSVEl+phw51yGWONEwSKp/5ic3Hz20uOg6uX7n0kYdnBCcz1LNLeo7PxPbo2y6u72O7PpbjYtoe/wDwzozttg8V9wAAAABJRU5ErkJggg==')",
              "backgroundSize": "cover",
              "display": "block"
            }
          }}></span>{`
  `}<picture parentName="a">{`
          `}<source parentName="picture" {...{
              "srcSet": ["/en/static/a1da5308df3cf234a2fc8737502e7a9a/e4706/AS_Server_Test_WebUI_02.avif 230w", "/en/static/a1da5308df3cf234a2fc8737502e7a9a/d1af7/AS_Server_Test_WebUI_02.avif 460w", "/en/static/a1da5308df3cf234a2fc8737502e7a9a/6d4a2/AS_Server_Test_WebUI_02.avif 918w"],
              "sizes": "(max-width: 918px) 100vw, 918px",
              "type": "image/avif"
            }}></source><source parentName="picture" {...{
              "srcSet": ["/en/static/a1da5308df3cf234a2fc8737502e7a9a/a0b58/AS_Server_Test_WebUI_02.webp 230w", "/en/static/a1da5308df3cf234a2fc8737502e7a9a/bc10c/AS_Server_Test_WebUI_02.webp 460w", "/en/static/a1da5308df3cf234a2fc8737502e7a9a/56bc7/AS_Server_Test_WebUI_02.webp 918w"],
              "sizes": "(max-width: 918px) 100vw, 918px",
              "type": "image/webp"
            }}></source>{`
          `}<source parentName="picture" {...{
              "srcSet": ["/en/static/a1da5308df3cf234a2fc8737502e7a9a/81c8e/AS_Server_Test_WebUI_02.png 230w", "/en/static/a1da5308df3cf234a2fc8737502e7a9a/08a84/AS_Server_Test_WebUI_02.png 460w", "/en/static/a1da5308df3cf234a2fc8737502e7a9a/e0885/AS_Server_Test_WebUI_02.png 918w"],
              "sizes": "(max-width: 918px) 100vw, 918px",
              "type": "image/png"
            }}></source>{`
          `}<img parentName="picture" {...{
              "className": "gatsby-resp-image-image",
              "src": "/en/static/a1da5308df3cf234a2fc8737502e7a9a/e0885/AS_Server_Test_WebUI_02.png",
              "alt": "MQTT- und HTTP-Alarmserver v2 API",
              "title": "MQTT- und HTTP-Alarmserver v2 API",
              "loading": "lazy",
              "decoding": "async",
              "style": {
                "width": "100%",
                "height": "100%",
                "margin": "0",
                "verticalAlign": "middle",
                "position": "absolute",
                "top": "0",
                "left": "0"
              }
            }}></img>{`
        `}</picture>{`
  `}</a>{`
    `}</span></p>
    <p>{`Make sure that `}<strong parentName="p">{`only Area1`}</strong>{` is selected and click on `}<strong parentName="p">{`Send Request`}</strong>{`. The next step will depend on the Smarthome system you are using. In this example I am going to use a simplified version of `}<a parentName="p" {...{
        "href": "/en/Advanced_User/Alarmserver_Queries_for_your_Smarthome/"
      }}>{`this Node-RED Flow`}</a>{` to provide the webhook that is going to be contacted by the camera. If you are using the test tool from the MQTT menu instead of the HTTP menu `}<a parentName="p" {...{
        "href": "/en/Advanced_User/INSTAR_MQTT_Broker/Windows_macOS_and_LINUX_Apps/"
      }}>{`connect the MQTT Explorer as debugging tool`}</a>{` and you will see the both alarmserver topics:`}</p>
    <ul>
      <li parentName="ul"><strong parentName="li">{`APIv1 MQTT Alarmserver Topic`}</strong>{`: `}<em parentName="li">{`prefix`}</em>{`/`}<em parentName="li">{`id`}</em>{`/status/alarm/triggered `}<code parentName="li" {...{
          "className": "language-text"
        }}>{`{ "val" : "1" }`}</code></li>
      <li parentName="ul"><strong parentName="li">{`APIv2 MQTT Alarmserver Topic`}</strong>{`: `}<em parentName="li">{`prefix`}</em>{`/`}<em parentName="li">{`id`}</em>{`/status/alarm/triggered/triggers `}<code parentName="li" {...{
          "className": "language-text"
        }}>{`{ "val" : "1" }`}</code></li>
    </ul>
    <p><span parentName="p" {...{
        "className": "gatsby-resp-image-wrapper",
        "style": {
          "position": "relative",
          "display": "block",
          "marginLeft": "auto",
          "marginRight": "auto",
          "maxWidth": "920px"
        }
      }}>{`
      `}<a parentName="span" {...{
          "className": "gatsby-resp-image-link",
          "href": "/en/static/7d37d5c057c7164dcec858de6cab1e9d/2bef9/AS_Server_Test_WebUI_03.png",
          "style": {
            "display": "block"
          },
          "target": "_blank",
          "rel": "noopener"
        }}>{`
    `}<span parentName="a" {...{
            "className": "gatsby-resp-image-background-image",
            "style": {
              "paddingBottom": "51.30434782608696%",
              "position": "relative",
              "bottom": "0",
              "left": "0",
              "backgroundImage": "url('data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABQAAAAKCAYAAAC0VX7mAAAACXBIWXMAAAsTAAALEwEAmpwYAAAB/UlEQVQoz3WP604TURSF52VQkWk7t9LOnDN35nI6dEpTaK2VAhISCTHx7jP4LugPtKBP95kOIUCiP77sS9Za2Vtzg5q+P6TrVZi9AqtXYG7fY7uKnl/juOp27pePWOvtfoGxXdDtKbRsekW1+MXk9A/jkxuK2U8mr2/YP/3N6Oia0XJFvVwxPFyxd3yNrL8jdy8Rw0v80Y/GW8yumJ6tOH63QhvsfWB08IWueIXjLRrccIkXLpt61/f9Q/rBEengArX/iVn9mSq/ICjfEBXnZNU5+/OPaFI4zKYj3J5B12k1tFtPaOkbtPQnD9jANDYZ7ARM9wYc7BYMYp8s8igjQRq4VFWG1jFMjo5PmL9ckOUFSg0IwxjTtDAME8OwGtY6y3aIREDoSkIRkMiIJIjJZNzsSzVAsyyb5fKMuh4hpSSOY6QQGIZBp9Np6l1vWRZxkpCXBdL38YMAP/DxwwAhBUopNKNjMJm8YDiskb4kSVKEELTb7Uehd4F1XTckSUKaprckKXmeMx6P0dbGxfI9F2+/MT74SpwO6Xbt/16oVEmW7RBFIVEUNYRh2ITN53M01/NQqkKVuyRJjud62Pa/Ax3Hodd36JibtI3n6Poz9NYtW/pTqkqhrYW6rrOlb9Fq6XQ67cb88NX7C23yPGUn20UGJVK6SCkahPBI04S/+u5kbr3jfu4AAAAASUVORK5CYII=')",
              "backgroundSize": "cover",
              "display": "block"
            }
          }}></span>{`
  `}<picture parentName="a">{`
          `}<source parentName="picture" {...{
              "srcSet": ["/en/static/7d37d5c057c7164dcec858de6cab1e9d/e4706/AS_Server_Test_WebUI_03.avif 230w", "/en/static/7d37d5c057c7164dcec858de6cab1e9d/d1af7/AS_Server_Test_WebUI_03.avif 460w", "/en/static/7d37d5c057c7164dcec858de6cab1e9d/7f308/AS_Server_Test_WebUI_03.avif 920w", "/en/static/7d37d5c057c7164dcec858de6cab1e9d/e3723/AS_Server_Test_WebUI_03.avif 1024w"],
              "sizes": "(max-width: 920px) 100vw, 920px",
              "type": "image/avif"
            }}></source><source parentName="picture" {...{
              "srcSet": ["/en/static/7d37d5c057c7164dcec858de6cab1e9d/a0b58/AS_Server_Test_WebUI_03.webp 230w", "/en/static/7d37d5c057c7164dcec858de6cab1e9d/bc10c/AS_Server_Test_WebUI_03.webp 460w", "/en/static/7d37d5c057c7164dcec858de6cab1e9d/966d8/AS_Server_Test_WebUI_03.webp 920w", "/en/static/7d37d5c057c7164dcec858de6cab1e9d/a9a89/AS_Server_Test_WebUI_03.webp 1024w"],
              "sizes": "(max-width: 920px) 100vw, 920px",
              "type": "image/webp"
            }}></source>{`
          `}<source parentName="picture" {...{
              "srcSet": ["/en/static/7d37d5c057c7164dcec858de6cab1e9d/81c8e/AS_Server_Test_WebUI_03.png 230w", "/en/static/7d37d5c057c7164dcec858de6cab1e9d/08a84/AS_Server_Test_WebUI_03.png 460w", "/en/static/7d37d5c057c7164dcec858de6cab1e9d/c0255/AS_Server_Test_WebUI_03.png 920w", "/en/static/7d37d5c057c7164dcec858de6cab1e9d/2bef9/AS_Server_Test_WebUI_03.png 1024w"],
              "sizes": "(max-width: 920px) 100vw, 920px",
              "type": "image/png"
            }}></source>{`
          `}<img parentName="picture" {...{
              "className": "gatsby-resp-image-image",
              "src": "/en/static/7d37d5c057c7164dcec858de6cab1e9d/c0255/AS_Server_Test_WebUI_03.png",
              "alt": "MQTT- und HTTP-Alarmserver v2 API",
              "title": "MQTT- und HTTP-Alarmserver v2 API",
              "loading": "lazy",
              "decoding": "async",
              "style": {
                "width": "100%",
                "height": "100%",
                "margin": "0",
                "verticalAlign": "middle",
                "position": "absolute",
                "top": "0",
                "left": "0"
              }
            }}></img>{`
        `}</picture>{`
  `}</a>{`
    `}</span></p>
    <p>{`For HTTP Webhook I can see the following in Node-RED:`}</p>
    <p><span parentName="p" {...{
        "className": "gatsby-resp-image-wrapper",
        "style": {
          "position": "relative",
          "display": "block",
          "marginLeft": "auto",
          "marginRight": "auto",
          "maxWidth": "900px"
        }
      }}>{`
      `}<a parentName="span" {...{
          "className": "gatsby-resp-image-link",
          "href": "/en/static/2007cd42c689f9b65e2c76f79cd66e03/1cfc2/AS_Server_Test_WebUI_04.png",
          "style": {
            "display": "block"
          },
          "target": "_blank",
          "rel": "noopener"
        }}>{`
    `}<span parentName="a" {...{
            "className": "gatsby-resp-image-background-image",
            "style": {
              "paddingBottom": "23.91304347826087%",
              "position": "relative",
              "bottom": "0",
              "left": "0",
              "backgroundImage": "url('data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABQAAAAFCAYAAABFA8wzAAAACXBIWXMAAAsTAAALEwEAmpwYAAAA9ElEQVQY02WQSU/DMBSE8///CmeECleoRMuBRW1RRSW6UIjjUNvP2Zo6H3JALGWkOTyN3mhmEmstIRw4HCJbQgh08MPuk1GvqgrvPUoprDHs9/v+TtMUrTV1XZNwhKYqyGcX5LcniF6g9APvegKEXo+PIoKIxzmHMaa/Y7BoniyXV6xWQ9brIS/bEXm2wT4NMZMz/OYepR65m18yX0151VuyLMPkOd5ZqrLsU8f0ETFxIpIiovBeURQZZeHwmym7xRhZjFBvzwzG5wyuT7mZjbDOIDuhtB5C+NOubdv/leNe4feGR3rd1Ehe4rUjNM3Xzt234Qf7Fn5s24QRlAAAAABJRU5ErkJggg==')",
              "backgroundSize": "cover",
              "display": "block"
            }
          }}></span>{`
  `}<picture parentName="a">{`
          `}<source parentName="picture" {...{
              "srcSet": ["/en/static/2007cd42c689f9b65e2c76f79cd66e03/e4706/AS_Server_Test_WebUI_04.avif 230w", "/en/static/2007cd42c689f9b65e2c76f79cd66e03/d1af7/AS_Server_Test_WebUI_04.avif 460w", "/en/static/2007cd42c689f9b65e2c76f79cd66e03/9b8d4/AS_Server_Test_WebUI_04.avif 900w"],
              "sizes": "(max-width: 900px) 100vw, 900px",
              "type": "image/avif"
            }}></source><source parentName="picture" {...{
              "srcSet": ["/en/static/2007cd42c689f9b65e2c76f79cd66e03/a0b58/AS_Server_Test_WebUI_04.webp 230w", "/en/static/2007cd42c689f9b65e2c76f79cd66e03/bc10c/AS_Server_Test_WebUI_04.webp 460w", "/en/static/2007cd42c689f9b65e2c76f79cd66e03/131f1/AS_Server_Test_WebUI_04.webp 900w"],
              "sizes": "(max-width: 900px) 100vw, 900px",
              "type": "image/webp"
            }}></source>{`
          `}<source parentName="picture" {...{
              "srcSet": ["/en/static/2007cd42c689f9b65e2c76f79cd66e03/81c8e/AS_Server_Test_WebUI_04.png 230w", "/en/static/2007cd42c689f9b65e2c76f79cd66e03/08a84/AS_Server_Test_WebUI_04.png 460w", "/en/static/2007cd42c689f9b65e2c76f79cd66e03/1cfc2/AS_Server_Test_WebUI_04.png 900w"],
              "sizes": "(max-width: 900px) 100vw, 900px",
              "type": "image/png"
            }}></source>{`
          `}<img parentName="picture" {...{
              "className": "gatsby-resp-image-image",
              "src": "/en/static/2007cd42c689f9b65e2c76f79cd66e03/1cfc2/AS_Server_Test_WebUI_04.png",
              "alt": "MQTT- und HTTP-Alarmserver v2 API",
              "title": "MQTT- und HTTP-Alarmserver v2 API",
              "loading": "lazy",
              "decoding": "async",
              "style": {
                "width": "100%",
                "height": "100%",
                "margin": "0",
                "verticalAlign": "middle",
                "position": "absolute",
                "top": "0",
                "left": "0"
              }
            }}></img>{`
        `}</picture>{`
  `}</a>{`
    `}</span></p>
    <ul>
      <li parentName="ul"><strong parentName="li">{`APIv1 HTTP Alarmserver Query`}</strong>{`: { active: "1", object: "0" }`}</li>
      <li parentName="ul"><strong parentName="li">{`APIv2 HTTP Alarmserver Query`}</strong>{`: { trigger: "1", object: "0" }`}</li>
    </ul>
    <p>{`The complete list of triggers for APIv2 is:`}</p>
    <p><strong parentName="p">{`Single Triggers`}</strong></p>
    <EuiSpacer mdxType="EuiSpacer" />
    <TriggerTable mdxType="TriggerTable" />
    <EuiSpacer mdxType="EuiSpacer" />
    <p><strong parentName="p">{`Combined Triggers`}</strong></p>
    <p>{`If more then one trigger is tripped their corresponding numbers are added up (excerpt of all available values):`}</p>
    <EuiSpacer mdxType="EuiSpacer" />
    <TriggerCombinedTable mdxType="TriggerCombinedTable" />
    <EuiSpacer mdxType="EuiSpacer" />
    <p>{`ETC. ETC. ETC.`}</p>
    <ul>
      <li parentName="ul"><a parentName="li" {...{
          "href": "/en/FAQs/"
        }}>{`FAQs`}</a>
        <ul parentName="li">
          <li parentName="ul"><a parentName="li" {...{
              "href": "/en/FAQs/IN-9420WQHD/"
            }}>{`IN-9420 WQHD`}</a></li>
          <li parentName="ul"><a parentName="li" {...{
              "href": "/en/FAQs/IN-9408WQHD/"
            }}>{`IN-9408 WQHD`}</a></li>
          <li parentName="ul"><a parentName="li" {...{
              "href": "/en/FAQs/IN-8415WQHD/"
            }}>{`IN-8415 WQHD`}</a></li>
          <li parentName="ul"><a parentName="li" {...{
              "href": "/en/FAQs/IN-8403WQHD/"
            }}>{`IN-8403 WQHD`}</a></li>
          <li parentName="ul"><a parentName="li" {...{
              "href": "/en/FAQs/IN-8401WQHD/"
            }}>{`IN-8401 WQHD`}</a></li>
          <li parentName="ul"><a parentName="li" {...{
              "href": "/en/FAQs/IN-9020FHD/"
            }}>{`IN-9020 Full HD`}</a></li>
          <li parentName="ul"><a parentName="li" {...{
              "href": "/en/FAQs/IN-9010FHD/"
            }}>{`IN-9010 Full HD`}</a></li>
          <li parentName="ul"><a parentName="li" {...{
              "href": "/en/FAQs/IN-9008FHD/"
            }}>{`IN-9008 Full HD`}</a></li>
          <li parentName="ul"><a parentName="li" {...{
              "href": "/en/FAQs/IN-8015FHD/"
            }}>{`IN-8015 Full HD`}</a></li>
          <li parentName="ul"><a parentName="li" {...{
              "href": "/en/FAQs/IN-8003FHD/"
            }}>{`IN-8003 Full HD`}</a></li>
          <li parentName="ul"><a parentName="li" {...{
              "href": "/en/FAQs/IN-8001FHD/"
            }}>{`IN-8001 Full HD`}</a></li>
          <li parentName="ul"><a parentName="li" {...{
              "href": "/en/FAQs/IN-7011HD/"
            }}>{`IN-7011 HD`}</a></li>
          <li parentName="ul"><a parentName="li" {...{
              "href": "/en/FAQs/IN-6014HD/"
            }}>{`IN-6014 HD`}</a></li>
          <li parentName="ul"><a parentName="li" {...{
              "href": "/en/FAQs/IN-6012HD/"
            }}>{`IN-6012 HD`}</a></li>
          <li parentName="ul"><a parentName="li" {...{
              "href": "/en/FAQs/IN-6001HD/"
            }}>{`IN-6001 HD`}</a></li>
          <li parentName="ul"><a parentName="li" {...{
              "href": "/en/FAQs/IN-5907HD/"
            }}>{`IN-5907 HD`}</a></li>
          <li parentName="ul"><a parentName="li" {...{
              "href": "/en/FAQs/IN-5905HD/"
            }}>{`IN-5905 HD`}</a></li>
          <li parentName="ul"><a parentName="li" {...{
              "href": "/en/FAQs/VGA_Series/"
            }}>{`VGA Series`}</a></li>
        </ul>
      </li>
    </ul>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      